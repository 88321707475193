import { types } from "../types/types";

const buscadorInitialState = {
    
    searchType: 'general',
    searchText: ''

}

export const buscadorReducer = ( state = buscadorInitialState, action ) => {

    switch (action.type) {
        
        case types.buscador.searchParams:
            return {
                ...state,
                ...action.payload
            }
        
        case types.buscador.data:
            return {
                ...state,
                data: action.payload
            }
    
        default:
            return state;
    }
}