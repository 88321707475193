import React from 'react'
import { useSelector } from 'react-redux';
import { words } from '../../../data/constants';

export const Loader = () => {

  const {languageActive}=useSelector(state => state.ui)

  return (
    <div className="loader">
        <h1>{words[languageActive].cargando}</h1>
        <div className="loader-element"></div>
    </div>
  )
}

export default Loader;
