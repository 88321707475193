import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faClock} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


export const RecordsSearchBar = () => {
        const [docsState, setDocs] = useState();
        const navigate=useNavigate();
        useEffect(() => {
            setDocs(JSON.parse(localStorage.getItem('searches')))
        }, [localStorage.getItem('searches')])
        
        const handleClickNavigate =(word)=>{
            navigate(`/buscador?general=${ word.replace(' ', '+') }`)
        }

        
return (
    <>
        <div className='_searchbar records'>

            <div className='_searchbar header-records'>

                Búsquedas recientes
            </div>
                <ul className='_searchbar list-container'>
                    {
                        docsState?.slice(0,5).map((doc, i)=>(
                            
                            <li className='_searchbar list-suggestions' key={i}>
                                <div className='_searhbar list-item' onClick={()=>handleClickNavigate(doc)}>
                                    <div>
                                        <FontAwesomeIcon icon={ faClock } size='lg' color='gray' />

                                    </div>
                                        <p>

                                            {` ${doc}`}
                                        </p>
                                    <div>

                                        <FontAwesomeIcon icon={ faExternalLinkAlt } size='lg' color='gray' />
                                    </div>
                                </div>
                                        
                            </li>
                            ))
                        }
                    
                    </ul>

                    </div>
    
    </>
)
}
