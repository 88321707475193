import React from 'react';
import ReactDOM from 'react-dom';

import { MainApp } from './MainApp';

import './styles/styles.scss';

ReactDOM.render(
  <MainApp/>,
  document.getElementById('root')
);
