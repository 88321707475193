import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { words } from '../../../data/constants';

export const Navbar = () => {

    const { loading } = useSelector( state => state.ui );
    const { languageActive } = useSelector(state => state.ui)
    return (
        <div className='__navbar navbar-container'>

            <Link to='/'>
                <button className={ loading ? '__navbar nav-btn info no-events' : '__navbar nav-btn info'}>
                    <img
                        src={require('../../../assets/img/nav_icons/informacion.png')}
                        alt="item_logo"
                    />
                    <p>{words[languageActive].informacion_nav}</p>
                </button>
            </Link>

            <Link to='/microdatos/especifica'>
                <button className={ loading ? '__navbar nav-btn micro no-events' : '__navbar nav-btn micro'}>
                    <img
                        src={require('../../../assets/img/nav_icons/microdatos.png')}
                        alt="item_logo"
                    />
                    <p>{words[languageActive].microdatos_nav}</p>
                </button>
            </Link>

            <Link to='/estaciones'>
                <button className={ loading ? '__navbar nav-btn estaciones no-events' : '__navbar nav-btn estaciones'}>
                    <img
                        src={require('../../../assets/img/nav_icons/estaciones.png')}
                        alt="item_logo"
                    />
                    <p>{words[languageActive].estaciones_nav}</p>
                </button>
            </Link>

            <a 
                href='https://acceso-datos-ambientales-invemar.hub.arcgis.com/'
                target='_blank'
                rel="noopener noreferrer"
            >
                <button className={ loading ? '__navbar nav-btn ocean-hub no-events' : '__navbar nav-btn ocean-hub'}>
                    <img
                        src={require('../../../assets/img/nav_icons/oceanhub.png')}
                        alt="item_logo"
                    />
                    <p>{words[languageActive].hub_nav}</p>
                </button>
            </a>
            
        </div>
    );
};
