import React from 'react';
import { SearchBar } from './SearchBar';
import { words } from '../../../data/constants';
import { useDispatch, useSelector } from 'react-redux';
import SPANISH from '../../../assets/img/icons/spanish.svg';
import ENGLISH from '../../../assets/img/icons/english.svg';
import { changeLanguage } from '../../../actions/ui';

import { RecordsSearchBar } from './RecordsSearchBar';

const languages = ["spanish","english"];

export const Header = () => {

    

    const dispatch = useDispatch()
    const { languageActive } = useSelector(state => state.ui)

    const handleChangeLanguage=()=>{      
        const nextLanguage = languages[ languages.indexOf(languageActive) + 1 ] || languages[0]
        dispatch( changeLanguage( nextLanguage ) )
    }

    
    
    return (
        <div className='_header header-container'>
            <div className='_header brand-container'>
                <div className='_header brand-icon'>
                    <img className='invemar-logo' src={require('../../../assets/img/logo-invemar.png')} alt='invemar-logo' />
                </div>
                <div className='_header brand-info'>
                    <h2>{words[languageActive].title}</h2>
                    <h3>{words[languageActive].subtitle}</h3> 
                </div>
                
            </div>
            <div className='_header brand2-container'>
                
                <div className='_header bd-search'>
                <div className='_searchbar container'>
                            
                    <SearchBar />
                    
                </div>

                </div> 
                
                
                <div 
                    className='language-container' 
                    onClick={ handleChangeLanguage }
                >
                    <div className='_header brand2-icon'>
                        <img src={languageActive === "spanish" ? SPANISH : ENGLISH} alt="language-switcher"/>

                    </div>
                </div>
                
            </div>
            
            

            

            
             
        </div>
    );
};
