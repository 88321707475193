import { types } from "../types/types"

const uiInitialState = {

    loading: false,
    loadingLayer: false,

    languageActive: 'spanish',
    searchRecordsOpen: false,

    maptools: {
        addLayerEnabled: false,
        checkDistanceEnabled: false,
        getCoordsEnabled: false,
        drawPolygonEnabled: false
    },

    layersActive: [],
}

export const uiReducer = ( state = uiInitialState, action ) => {

    switch (action.type) {
        
        case types.ui.loading:
            return {
                ...state,
                loading: action.payload
            }

        case types.ui.maptools:
            return {
                ...state,
                maptools: { ...action.payload }
            }

        case types.ui.loadingLayer: 
            return {
                ...state,
                ...action.payload
            }
        
        case types.ui.languageActive: 
            return {
                ...state,
                languageActive: action.payload
            }

        case types.ui.layers.addLayer:
            return {
                ...state,
                layersActive: [ action.payload, ...state.layersActive]
            }
        
        case types.ui.layers.removeLayer:
            return {
                ...state,
                layersActive: state.layersActive.filter(
                    layer => layer !== action.payload 
                )
            }

        case types.ui.layers.resetLayers:
            return {
                ...state,
                layersActive: []
            }

        default:
            return state
    }
}