import { types } from "../types/types";

const estacionesInitialState = {
    
    sidePanelActive: true,
    sidePanelBtnActive: 'filtros',

    searchFilter: {

        options: null,
        
        searchParams: {
            topicFilter: false,
            topicValue: 'todos',
            subtopicFilter: false,
            subtopicValue: 'todos',
            projectFilter: false,
            projectValue: 'todos',
        },

        searchError: null
    },
}

export const estacionesReducer = ( state = estacionesInitialState, action ) => {
    
    switch ( action.type ) {

        case types.estaciones.sidePanelActive:
            return {
                ...state,
                sidePanelActive: action.payload
            }
        
        case types.estaciones.sidePanelBtnActive:
            return {
                ...state,
                sidePanelBtnActive: action.payload
            }

        case types.estaciones.searchFilter.setOptions:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    options: action.payload
                }
            }

        case types.estaciones.searchFilter.updateSearch:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    searchParams: action.payload
                }
            }

        case types.estaciones.searchFilter.resetSearch:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    searchParams: estacionesInitialState.searchFilter.searchParams
                }
            }

        case types.estaciones.searchFilter.searchError:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    searchError: action.payload
                }
            }

        case types.estaciones.searchFilter.resetSearchError:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    searchError: null
                }
            }
    
        default:
            return state;
    }
}