import { types } from "../types/types";
import { searchInitialDate, searchFinalDate } from "../data/datesConfig";

const microdatosInitialState = {
    
    sidePanelActive: true,
    sidePanelBtnActive: 'filtros',

    searchFilter: {

        options: null,
        filterActive: 'especifica',

        lastEvents: {
            generalFilterLE: null,
            specificFilterLE: null,
            locationLE: null
        },

        searchParams: {
            generalFilter: null,
            specificFilter: null,
            searchDoi: 'https://n2t.net/ark:/',
            initDate: searchInitialDate,
            finalDate: searchFinalDate,
            locOption: 'todas',
            location: 'todas',
            polygons: [],
        }
    },

    preview: {
        control: false,
        active: false,
        data: null,
        error: null,
        tabs: null,
        activeTab: null,
        downloadMets: [],
        
        messagePanel: {
            active: false,
            type: null,
        }
    },

    maptools: false,

    layersActive: [],
}

export const microdatosReducer = ( state = microdatosInitialState, action ) => {
    
    switch ( action.type ) {

        case types.microdatos.sidePanelActive:
            return {
                ...state,
                sidePanelActive: action.payload
            }
        
        case types.microdatos.sidePanelBtnActive:
            return {
                ...state,
                sidePanelBtnActive: action.payload
            }

        case types.microdatos.searchFilter.setOptions:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    options: action.payload
                }
            }

        case types.microdatos.searchFilter.filterActive:  
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    filterActive: action.payload
                },
                preview: microdatosInitialState.preview
            }

        case types.microdatos.searchFilter.registerEvent:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    lastEvents: {
                        ...state.searchFilter.lastEvents,
                        [ action.payload.param ]: action.payload.event
                    }
                }
            }

        case types.microdatos.searchFilter.updateSearch:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    searchParams: {
                        ...state.searchFilter.searchParams,
                        [ action.payload.param ]: action.payload.value
                    }
                }
            }

        case types.microdatos.searchFilter.resetSearch:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    searchParams: microdatosInitialState.searchFilter.searchParams
                }
            }

        case types.microdatos.searchFilter.addLocations:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    searchParams: {
                        ...state.searchFilter.searchParams,
                        location: [ ...state.searchFilter.searchParams.location, ...action.payload ] 
                    }
                }
            }

        case types.microdatos.preview.controlActive:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    control: action.payload
                }
            }

        case types.microdatos.preview.active:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    active: action.payload
                }
            }

        case types.microdatos.preview.errorData:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    error: action.payload
                }
            }

        case types.microdatos.preview.reset:
            return {
                ...state,
                preview: microdatosInitialState.preview
            }

        case types.microdatos.preview.initialize:
            return {
                ...state,
                sidePanelActive: false,
                preview: {
                    ...microdatosInitialState.preview,
                    control: true,
                    active: true,
                    data: action.payload.data,
                    tabs: action.payload.tabs,
                    activeTab: action.payload.activeTab
                }
            }

        case types.microdatos.preview.panelActiveTab:   
            return {
                ...state,
                preview: {
                    ...state.preview,
                    activeTab: action.payload
                }
            }
        
        case types.microdatos.preview.downloadMets:   
            return {
                ...state,
                preview: {
                    ...state.preview,
                    downloadMets: action.payload
                }
            }

        case types.microdatos.preview.messagePanel:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    messagePanel: {
                        active: action.payload.active,
                        type: action.payload.message
                    }
                }
            }

        case types.microdatos.finishDownload:
            return {
                ...state,
                preview: {
                    ...state.preview,
                    messagePanel: {
                        active: false,
                        type: null
                    }
                }
            }

        case types.microdatos.maptools.changeState:
            return {
                ...state,
                maptools: action.payload
            }
        
        case types.microdatos.maptools.addDraw:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    searchParams: {
                        ...state.searchFilter.searchParams,
                        polygons: [ ...state.searchFilter.searchParams.polygons, action.payload ]
                    }
                }
            }

        case types.microdatos.maptools.removeDraw:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    searchParams: {
                        ...state.searchFilter.searchParams,
                        polygons: state.searchFilter.searchParams.polygons.filter( polygon => polygon.id !== action.payload )
                    }      
                }
            }
    
        default:
            return state;
    }
}