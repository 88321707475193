import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { uiReducer } from '../reducers/uiReducer';
import { informacionReducer } from '../reducers/informacionReducer' 
import { buscadorReducer } from '../reducers/buscadorReducer';
import { microdatosReducer } from '../reducers/microdatosReducer';
import { estacionesReducer } from '../reducers/estacionesReducer';

const composeEnhancers = ( process.env.NODE_ENV !== 'production' && window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    ui: uiReducer,
    informacion: informacionReducer,
    microdatos: microdatosReducer,
    estaciones: estacionesReducer,
    buscador: buscadorReducer,
});

export const store = createStore( 
    reducers, 
    composeEnhancers (
        applyMiddleware( thunk )
    ),
);