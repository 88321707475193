
import { types } from "../types/types";

export const changeSearchParam = ( searchType, searchText ) => ({
    type: types.buscador.searchParams,
    payload: {
        searchType,
        searchText
    },

    
}); 