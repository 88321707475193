export const types = {

    ui: {
        loading: '[UI] AppLoading',
        maptools: '[UI] Maptools',
        loadingLayer: '[INFO] Loading Layer',

        languageActive: '[UI] SetLanguageActive',

        layers: {
            addLayer: '[MICRO] AddLayer',
            removeLayer: '[MICRO] RemoveLayer',
            resetLayers: '[MICRO] ResetLayers'
        },
        topSeachesAndDocs:'[UI] TopSearchesAndDocs'
        
    },    

    informacion: {
        btnActive: '[INFO] BtnActive',
        errorLayer: '[INFO] ErrorLayer'
    },

    microdatos: {  
        
        sidePanelActive: '[MICRO] SidePanelActive',
        sidePanelBtnActive: '[MICRO] SidePanelBtnActive',
        
        searchFilter: {
            setOptions: '[MICRO] SetFilterOptions',
            filterActive: '[MICRO] FilterActive',
            locationFilterActive: '[MICRO] LocationFilterActive',
            registerEvent: '[MICRO] RegisterEvent',
            updateSearch: '[MICRO] UpdateSearch',
            resetSearch: '[MICRO] ResetSearch',
            addLocations: '[MICRO] AddLocations'
        },
        
        preview: {
            controlActive: '[MICRO] PreviewControl',
            active: '[MICRO] PreviewPanel',
            errorData: '[MICRO] PreviewErrorData',
            reset: '[MICRO] PreviewReset',
            initialize: '[MICRO] PreviewInitialize',
            panelActiveTab: '[MICRO] PreviewActiveTab',
            downloadMets: '[MICRO] PreviewDownloadMets',
            messagePanel: '[MICRO] PreviewMessagePanel'     
        },

        finishDownload: '[MICRO] FinishDownloading',

        maptools: {
            changeState: '[MICRO] ChangeMaptoolsState',
            addDraw: '[MICRO] AddDraw',
            removeDraw: '[MICRO] RemoveDraw',
        }
    },

    estaciones: {  

        sidePanelActive: '[EST] SidePanelActive',
        sidePanelBtnActive: '[EST] SidePanelBtnActive',
        
        searchFilter: {
            setOptions: '[EST] SetFilterOptions',
            registerEvent: '[EST] RegisterEvent',
            updateSearch: '[EST] UpdateSearch',
            resetSearch: '[EST] ResetSearch',
            searchError: '[EST] SearchError',
            resetSearchError: '[EST] ResetSearchError',
        }
    },

    buscador: {
        searchParams: '[SEARCH] SearchParams'
    }    
};