import React, { useEffect } from 'react';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { AppRouter } from './routes/AppRouter';
import ReactGA from 'react-ga';


ReactGA.initialize('UA-2300620-9');
export const MainApp = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    },[])
    
    return (

        <Provider store={ store }>

            <AppRouter />

        </Provider>

    );

};