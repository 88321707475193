import { useState } from "react";

export const useForm = ( initialStatate = {} ) => {

    const [ values, setValues ] = useState( initialStatate );
    
    const reset = () => {
        setValues ( initialStatate );
    }

    const handleInputChange = ({ target }) => {
  
        setValues({
            ...values,
            [ target.name ]: target.value
        })
    }

    return [ values, handleInputChange, reset ]
};