import { types } from "../types/types";

const informacionInitialState = {

    btnActive: 'ecosistemas',
    errorLayer: null
}

export const informacionReducer = ( state = informacionInitialState, action ) => {

    switch (action.type) {

        case types.informacion.btnActive:
            return {
                ...state,
                btnActive: action.payload
            };
        
        case types.informacion.errorLayer:
            return {
                ...state,
                errorLayer: action.payload
            }
    
        default:
            return state;
    }
}