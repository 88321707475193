import React, { Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route, useOutletContext } from 'react-router-dom';

import { Header } from '../components/shared/header/Header';
import { Navbar } from '../components/shared/navbar/Navbar';
import { Loader } from '../components/shared/loader/Loader';
import { useSelector } from 'react-redux';
import ModalSearch from '../components/buscador/ModalSearch';

const Informacion = React.lazy(() => import(/* webpackChunkName: "Informacion" */ '../components/informacion/Informacion'));
const Microdatos = React.lazy(() => import( /* webpackChunkName: "Microdatos" */ '../components/microdatos/Microdatos'));
const Estaciones = React.lazy(() => import( /* webpackChunkName: "Estaciones" */ '../components/estaciones/Estaciones'));
const Buscador = React.lazy(() => import( /* webpackChunkName: "Buscador" */ '../components/buscador/Buscador'));

export const AppRouter = () => {
    const {searchRecordsOpen}=useSelector(state=>state.ui)

    return (  
        <Suspense fallback={ <Loader /> }>
            <div className='main-container'> 
                <Router>
                    <Header />
                    <Navbar />
                    <Routes>
                        <Route path='/' element={ <Informacion /> } />
                        <Route path='/microdatos/:filterActive' element={ <Microdatos /> }/>
                        <Route path='/estaciones' element={ <Estaciones /> } />
                        <Route path='/buscador' element={ <Buscador /> } />
                        <Route path='/*' element={ <Informacion /> } />
                    </Routes>
                </Router>
            </div>
        </Suspense>    
    );
};
