import { types } from "../types/types";

export const setLoading = ( state ) => ({
    type: types.ui.loading, 
    payload: state
});

export const changeMaptoolsState = ( newState ) => ({
    type: types.ui.maptools,
    payload: newState
});

export const loadingLayer = ( state ) => ({
    type: types.ui.loadingLayer,
    payload: {
        loading: state,
        loadingLayer: state
    }
});

export const changeLanguage = ( language ) => ({
    type: types.ui.languageActive,
    payload: language,
    
})

// Acciones sobre el mapa
export const addLayer = ( layerTitle ) => ({
    type: types.ui.layers.addLayer,
    payload: layerTitle
});

export const removeLayer = ( layerTitle ) => ({
    type: types.ui.layers.removeLayer,
    payload: layerTitle
});

export const resetLayers = () => ({
    type: types.ui.layers.resetLayers,
});

