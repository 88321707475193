import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string'
import { words } from '../../../data/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { useForm } from '../../../hooks/useForm';
import { changeSearchParam } from '../../../actions/buscador';
import { RecordsSearchBar } from './RecordsSearchBar';

export const SearchBar = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { loading } = useSelector( state => state.ui );
    const { searchType, searchText } = useSelector( state => state.buscador );
    const { languageActive } = useSelector(state => state.ui)
    
    const parsed = Object.entries( queryString.parse( location.search ) );

    const [ option, text ] = parsed.length > 0
                                ? parsed[0]
                                : [ searchType, searchText ];
    
    const [ searchFocus, setSearchFocus ] = useState( false );
    const [ selectOption, setSelectOption ] = useState( option );

    const [ formValues, handleInputChange ] = useForm({
        inputText: text
    });

    const { inputText } = formValues;
    
    const [recordsActive, setRecordsActive] = useState(false)
    
    const [searches, setSearches] = useState([])

    
    const handleNavigate = ( e ) => {
        
        if ( e.type === 'submit' ) {
            e.preventDefault();
        }

        if ( inputText.trim().length > 2 && !loading ) {
            dispatch( changeSearchParam( selectOption, inputText ) );
            navigate(`/buscador?${ selectOption }=${ inputText.replace(' ', '+') }`)
            setSearches(searchs => [...searchs, inputText])
            localStorage.setItem('searches', JSON.stringify(searches))
        }  
    }   
    const inputOnFocus=()=>{
        setSearchFocus(true)
        setRecordsActive(true)
    }
    const inputOnBlur =()=>{
        setSearchFocus(false)
        
    }
    return (
        <>
        
            <div className={ searchFocus ? '_searchbar searchbar-container active' : '_searchbar searchbar-container'} >

                <select 
                    key={ searchType }
                    name="searchOptions"
                    className='searchbar-options'
                    value={ selectOption }
                    onChange={ ({ target }) => setSelectOption( target.value ) }
                >
                    <option value="general">{words[languageActive].general_option}</option> 
                    <option value="titulo">{words[languageActive].titulo_option}</option> 
                    <option value="revista">{words[languageActive].revista_option}</option> 
                    <option value="autor">{words[languageActive].autor_option}</option> 
                </select>


                    <form
                        className='fw' 
                        onSubmit={ handleNavigate }
                    >
                        <input 
                            key={ searchText }
                            name='inputText'
                            className={`searchbar ${ loading && 'off' } `}  
                            placeholder={words[languageActive].buscador_hd}
                            autoComplete='off' 
                            value={ inputText }
                            onChange={ handleInputChange }
                            onFocus={ inputOnFocus }
                            onBlur={ inputOnBlur }
                            
                        />
                    </form>

                <button 
                    className='searchbar-btn'
                    onClick={ handleNavigate }
                    aria-label='search-btn'
                    disabled={ loading }
                >
                    <FontAwesomeIcon icon={ faSearch } size='lg' color='white' />
                </button>
                {
                    recordsActive ?
                    <button 
                        className='searchbar-btn'
                        onClick={ () =>setRecordsActive(false)}
                        aria-label='search-btn'
                        disabled={ loading }
                    >
                        <FontAwesomeIcon icon={ faAngleUp } size='lg' color='white' />
                    </button>
                    :
                    <button 
                        className='searchbar-btn'
                        onClick={ () =>setRecordsActive(true)}
                        aria-label='search-btn'
                        disabled={ loading }
                    >
                        <FontAwesomeIcon icon={ faAngleDown } size='lg' color='white' />
                    </button>

                }
            </div>
            
            {
                recordsActive &&
                <RecordsSearchBar/>
            }
        </>

    );
};
